import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { ENV } from '@trtl/env';

import { Features, UiDto } from './models';

@Injectable({ providedIn: 'root' })
export class ConfigApiService {

  private readonly http = inject(HttpClient);
  private readonly env = inject(ENV);

  public getUi() {
    return this.http.get<UiDto>(`${this.env.api}/api/v1/uis/active`);
  }

  public getFeatures() {
    return this.http.get<Features>(`${this.env.api}/api/v1/features/active`);
  }
}
