import { Features, UiDto } from './models';

export class SetUi {
  static readonly type = '[Config] Set UI';
  constructor(public readonly ui: UiDto) {}
}

export class SetFeatures {
  static readonly type = '[Config] Set Features';
  constructor(public readonly features: Features) {}
}
