import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  provideExperimentalZonelessChangeDetection,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  ActivatedRouteSnapshot,
  Routes,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideTransloco } from '@ngneat/transloco';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';

import { AuthState } from '@trtl/auth';
import { ENV } from '@trtl/env';
import { I18nService } from '@trtl/i18n';
import { ScreenState } from '@trtl/state/screen';

import { environment } from '../environments/environment';
import { SentryHandler } from '../sentry';

import { TrtlErrorStateMatcher } from './_shared';
import { authInterceptor, roleInterceptor } from './_shared/interceptors';
import { ConfigService, ConfigState } from './_shared/state';

const onboardingGuard = (route: ActivatedRouteSnapshot) => {
  const { action, payload } = route.queryParams;
  if (action && payload) {
    sessionStorage.setItem('onboarding', JSON.stringify({ action, payload }));
  }

  return true;
};

const routes: Routes = [
  {
    path: '',
    canActivate: [onboardingGuard],
    children: [
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth'),
      },
      {
        path: 'trtlAdmin',
        redirectTo: 'doctor',
        pathMatch: 'full',
      },
      {
        path: 'groupAdmin',
        redirectTo: 'doctor',
        pathMatch: 'full',
      },
      {
        path: 'doctor',
        loadChildren: () => import('./doctor'),
      },
      {
        path: 'patient',
        loadChildren: () => import('./patient'),
      },
      {
        path: 'support',
        loadChildren: () => import('./support'),
      },
    ],
    providers: [
      importProvidersFrom(
        NgxsModule.forFeature([
          AuthState,
        ]),
      ),
    ],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideAnimationsAsync(),
    provideExperimentalZonelessChangeDetection(),
    provideHttpClient(withInterceptors([authInterceptor, roleInterceptor])),
    provideTransloco({
      config: {
        availableLangs: ['en', 'ar'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        failedRetries: 3,
      },
      loader: I18nService,
    }),

    importProvidersFrom(
      NgxsModule.forRoot(
        [
          ConfigState,
          ScreenState,
        ],
        {
          selectorOptions: {
            injectContainerState: false,
            suppressErrors: false,
          },
        },
      ),
      NgxsReduxDevtoolsPluginModule.forRoot(),
    ),

    {
      provide: ENV,
      useFactory: () => environment,
    },
    {
      provide: ErrorStateMatcher,
      useClass: TrtlErrorStateMatcher,
    },
    {
      provide: ErrorHandler,
      useClass: SentryHandler,
    },
    provideAppInitializer(() => {
        const initializerFn = ((config: ConfigService) => () => config.getUi())(inject(ConfigService));

        return initializerFn();
      }),
  ],
};
