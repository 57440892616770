import { Injectable } from '@angular/core';
import { Action, createPropertySelectors, State, StateContext, StateToken } from '@ngxs/store';

import { SetFeatures, SetUi } from './config.actions';
import { Features, UiDto } from './models';

export interface ConfigStateModel {
  ui: UiDto;
  features: Features;
}


export interface SkuDto {
  id: string;
  name: string;
  features: Record<string, boolean>;
}

export const CONFIG_STATE = new StateToken<ConfigStateModel>('CONFIG_STATE');

@State({
  name: CONFIG_STATE,
  defaults: null,
})
@Injectable()
export class ConfigState {

  public static slices = createPropertySelectors(CONFIG_STATE);
  public static ui = createPropertySelectors(ConfigState.slices.ui);

  @Action(SetUi)
  setConfig(
    { patchState }: StateContext<ConfigStateModel>,
    { ui }: SetUi,
  ) {
    patchState({ ui });
    document.documentElement.style.setProperty('--primary-color', ui.colors.primary);
    document.documentElement.style.setProperty('--secondary-color', ui.colors.secondary);
    document.documentElement.style.setProperty('--warning-color', ui.colors.warning);
    (document.querySelector('link[rel="icon"]') as HTMLLinkElement).href = ui.favicon;
    if (ui.seo) {
      document.querySelector('title')!.innerText = ui.seo.title;
      (document.querySelector('meta[name="description"]') as HTMLMetaElement).innerText = ui.seo.description;
    }
  }

  @Action(SetFeatures)
  setFeatures(
    { patchState }: StateContext<ConfigStateModel>,
    { features }: SetFeatures,
  ) {
    patchState({ features });
  }
}
